<template>
	<div class="wrapper d-flex flex-column align-items-center">
		<div class="load-bar">
			<div class="color"></div>
		</div>
		<div class="issues-header d-flex justify-content-between align-items-center w-100">
			<div>
				<h2 class="my-0">Web Development Issues</h2>
			</div>
			<div>
				<b-button variant="outline-secondary" size="sm" style="background: white;" @click="showTableView = !showTableView">Table View</b-button>
				<b-button :to="{ name: 'newissue' }" variant="success" size="sm" class="font-weight-bolder ml-2">New Issue</b-button>
			</div>
		</div>
		<IssueTableView :data="data" v-if="showTableView" />
		<div class="issue-table" v-if="!showTableView">
			<!-- HEADERS -->
			<div class="issue-header p-5 d-flex justify-content-between">
				<div class="left d-flex align-items-center">
					<div class="d-flex align-items-center mr-4">
						<inline-svg src="/media/svg/icons/Media/Vynil.svg" width="20" class="mr-1" />
						<span
							:class="[{ 'active-status': status == 'open' }, 'status']"
							@click="
								setFilter('status', 'open');
								status = 'open';
							"
						>
							{{ counts.open }} Open</span
						>
					</div>
					<div class="d-flex align-items-center ">
						<inline-svg src="/media/svg/icons/Navigation/Check.svg" width="18" class="closed-icon" />
						<span
							:class="[{ 'active-status': status == 'closed' }, 'status']"
							@click="
								setFilter('status', 'closed');
								status = 'closed';
							"
							>{{ counts.closed }} Closed</span
						>
					</div>
				</div>
				<div class="right d-flex">
					<b-dropdown text="Issue Type" variant="bg-transparent" right lazy>
						<b-dropdown-header class="filter-header">Filter by Type</b-dropdown-header>
						<b-dropdown-divider></b-dropdown-divider>
						<b-dropdown-item-button v-for="type in filters.type" :key="type" @click="setFilter('type', type)">
							<div class="d-flex align-items-center">
								<div style="width: 30px">
									<inline-svg src="/media/svg/icons/Navigation/Check.svg" width="18" v-if="q.type == type" />
								</div>
								<div>{{ type }}</div>
							</div>
						</b-dropdown-item-button>
					</b-dropdown>
					<b-dropdown text="Project" variant="bg-transparent" right lazy>
						<b-dropdown-header class="filter-header">Filter by Project</b-dropdown-header>
						<b-dropdown-divider></b-dropdown-divider>
						<b-dropdown-item-button v-for="type in filters.project" :key="type" @click="setFilter('project', type)">
							<div class="d-flex align-items-center">
								<div style="width: 30px">
									<inline-svg src="/media/svg/icons/Navigation/Check.svg" width="18" v-if="q.project == type" />
								</div>
								<div>{{ type }}</div>
							</div>
						</b-dropdown-item-button>
					</b-dropdown>
					<b-dropdown text="Severity" variant="bg-transparent" right lazy>
						<b-dropdown-header class="filter-header">Filter by Severity</b-dropdown-header>
						<b-dropdown-divider></b-dropdown-divider>
						<b-dropdown-item-button v-for="type in filters.severity" :key="type" @click="setFilter('severity', type)">
							<div class="d-flex align-items-center">
								<div style="width: 30px">
									<inline-svg src="/media/svg/icons/Navigation/Check.svg" width="18" v-if="q.severity == type" />
								</div>
								<div>{{ type }}</div>
							</div>
						</b-dropdown-item-button>
					</b-dropdown>
					<b-dropdown text="Requestor" variant="bg-transparent" right lazy @show="loadFilters()">
						<b-dropdown-header class="filter-header">Filter by Requestor</b-dropdown-header>
						<b-dropdown-form form-class="px-2">
							<b-form-input size="sm"></b-form-input>
						</b-dropdown-form>
						<b-dropdown-divider></b-dropdown-divider>
						<b-dropdown-item-button v-for="user in filters.users" :key="user.username" @click="setFilter('username', user.username)">
							<div class="d-flex align-items-center">
								<div style="width: 30px">
									<inline-svg src="/media/svg/icons/Navigation/Check.svg" width="18" v-if="q.username == user.username" />
								</div>
								<div>{{ user.name }}</div>
							</div>
						</b-dropdown-item-button>
					</b-dropdown>
					<b-dropdown text="Developer" variant="bg-transparent" right lazy>
						<b-dropdown-header class="filter-header">Filter by Developer</b-dropdown-header>
						<b-dropdown-divider></b-dropdown-divider>
						<b-dropdown-item-button v-for="type in filters.developer" :key="type" @click="setFilter('developer', type)">
							<div class="d-flex align-items-center">
								<div style="width: 30px">
									<inline-svg src="/media/svg/icons/Navigation/Check.svg" width="18" v-if="q.developer == type" />
								</div>
								<div>{{ type }}</div>
							</div>
						</b-dropdown-item-button>
					</b-dropdown>
					<b-dropdown text="Sort" variant="bg-transparent" right lazy>
						<b-dropdown-header class="filter-header">Sort By</b-dropdown-header>
						<b-dropdown-divider></b-dropdown-divider>
						<b-dropdown-item-button v-for="sort in sortBy" :key="sort.col" @click="setSort(sort.col)">
							<div class="d-flex align-items-center">
								<div style="width: 30px">
									<inline-svg src="/media/svg/icons/Navigation/Check.svg" width="18" v-if="q.sort == sort.col" />
								</div>
								<div>{{ sort.label }}</div>
							</div>
						</b-dropdown-item-button>
					</b-dropdown>
				</div>
			</div>
			<!-- END HEADERS -->

			<hr class="m-0" />

			<!-- THE LIST -->
			<div class="list">
				<div class="issue p-5 d-flex align-items-center justify-content-between" v-for="issue in data" :key="issue.id">
					<div class=" d-flex align-items-center">
						<div class=" mr-4 svg-icon svg-icon-success" v-if="status == 'open'">
							<inline-svg src="/media/svg/icons/Media/Vynil.svg" width="20" class="mr-1" />
						</div>
						<div class=" mr-4 svg-icon svg-icon-info" v-else>
							<inline-svg src="/media/svg/icons/Navigation/Check.svg" width="20" class="mr-1" />
						</div>
						<div class="d-flex flex-column">
							<div class="font-weight-bolder">
								<span :id="'issue_' + issue.id">
									<router-link :to="{ name: 'issue', params: { id: +issue.id } }">
										{{ issue.title }}
										<span
											class="label label-rounded label-inline font-weight-bolder ml-2"
											:class="{
												'label-danger': issue.type == 'Bug',
												'label-success': issue.type == 'Feature',
												'label-info': issue.type == 'Report',
												'label-primary': issue.type == 'Info',
												'label-secondary': issue.type == 'Other',
											}"
										>
											{{ issue.type }}
										</span>
									</router-link>
								</span>
							</div>
							<div class="text-dark-50">#{{ issue.id }} opened on {{ issue.created }} by {{ issue.name }}</div>
						</div>
					</div>
					<div class="details d-flex">
						<div class="detail-box">
							<div class="d-flex" v-if="issue.severity == 'High' || issue.severity == 'Medium'">
								<div
									class="label label-rounded label-inline font-weight-bolder"
									:class="{
										'label-danger': issue.severity == 'High',
										'label-warning': issue.severity == 'Medium',
									}"
								>
									{{ issue.severity }}
								</div>
							</div>
						</div>
						<div class="detail-box">{{ issue.developer.split(' ')[0] }}</div>
						<div class="detail-box">
							<div v-if="issue.comments > 0">
								<router-link :to="{ name: 'issue', params: { id: +issue.id } }">
									<div class="comment-count d-flex justify-content-center">
										<i class="flaticon-speech-bubble-1" style="font-size: 13px; padding-top: 1px;"></i>
										<div class="ml-2" style="font-size: 11px">
											{{ issue.comments }}
										</div>
									</div>
								</router-link>
							</div>
						</div>
					</div>
					<b-popover :target="'issue_' + issue.id" triggers="hover" placement="topright">
						<template #title>{{ issue.title }}</template>
						{{ issue.description || issue.actual }}
					</b-popover>
				</div>
			</div>
		</div>
		<div class="my-3">
			<b-pagination
				v-model="currentPage"
				:total-rows="counts[status]"
				:per-page="25"
				hide-goto-end-buttons
				next-text="Next >"
				prev-text="< Previous"
			></b-pagination>
		</div>
	</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { START_SITES_STATUS, STOP_SITES_STATUS, START_VOIP_STATUS, STOP_VOIP_STATUS } from '@/core/services/store/notification.module';

export default {
	name: 'Issues',
	components: {
		IssueTableView: () => import('@/view/content/widgets/components/Issues/IssueTableView'),
	},
	data() {
		return {
			counts: {
				open: 0,
				closed: 0,
			},
			data: [],
			q: {},
			status: 'open',
			currentPage: 1,
			showTableView: false,
			fields: {},
			filters: {
				type: ['Bug', 'Feature', 'Report', 'Info', 'Other'],
				project: ['VSAT Portal', 'NOC Portal', 'Wallboard', 'CM4K'],
				severity: ['Low', 'Medium', 'High'],
				users: [],
				developer: ['Josh Palacios', 'Paul Lime'],
			},
			sortBy: [
				{ label: 'Newest', col: 'created-desc' },
				{ label: 'Oldest', col: 'created-asc' },
				{ label: 'Recently Updated', col: 'updated-desc' },
				{ label: 'Most Commented', col: 'comments-desc' },
				{ label: 'Least Commented', col: 'comments-asc' },
			],
			loaded: false,
		};
	},
	computed: {},
	methods: {
		getIssues() {
			this.$http.get('issues', this.q).then(({ data }) => {
				this.data = data;
				this.loaded = true;
			});
		},
		getCounts() {
			this.$http.get('issues/count', this.q).then(({ data }) => {
				this.counts.open = data.open || 0;
				this.counts.closed = data.closed || 0;
			});
		},
		setFilter(filter, value) {
			if (this.q[filter] == value) {
				delete this.q[filter];
			} else {
				this.q[filter] = value;
			}
			this.getIssues();
			this.getCounts();
		},
		setSort(sort) {
			if (this.q.sort == sort) {
				delete this.q.sort;
			} else {
				this.q.sort = sort;
			}
			this.getIssues();
		},
		loadFilters() {
			this.$http.get('issues/filters').then(({ data }) => {
				this.filters.users = data.users;
			});
		},
	},
	created() {
		this.loaded = false;
		this.getIssues();
		this.getCounts();
		this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Web Dev Issues' }]);
		this.$store.commit(STOP_SITES_STATUS);
		this.$store.commit(STOP_VOIP_STATUS);
	},
};
</script>

<style lang="scss" scoped>
.wrapper {
	background: white;
	height: 100%;
}
// .load-bar {
// 	height: 3px;
// 	width: 100%;
// 	.color {
// 		background: $primary-hover;
// 		height: 3px;
// 		width: 50%;
// 	}
// }
.issues-header {
	max-width: 1250px;
	margin: 25px 0 15px;
}
.issue-table {
	background: white;
	margin: 0;
	width: 100%;
	max-width: 1250px;
	border-radius: 7px;
	border: 1px solid hsla(210, 18%, 87%, 1);
}
.issue-header {
	max-height: 55px;
	::v-deep button.btn {
		padding: 0 1rem;
	}
}
.issue {
	max-height: 65px;
	border-bottom: 1px solid hsla(210, 18%, 87%, 1);
	a {
		color: black;
	}
}
.issue:hover {
	background: #f6f8fa;
}
.status {
	cursor: pointer;
	color: rgb(129, 129, 129);
}
.status:hover {
	font-weight: 500;
	color: rgb(0, 0, 0);
}
.active-status {
	font-weight: 700;
	color: #3699ff;
}
.details {
	width: 265px;
	display: flex;
	justify-content: space-between;
}
.detail-box {
	width: 70px;
	text-align: center;
}
.comment-count i {
	color: black;
}
.comment-count:hover,
.comment-count:hover i {
	color: $primary-hover;
}
::v-deep svg.open-icon g path {
	fill: $success;
}
::v-deep svg.closed-icon g path {
	fill: rgb(129, 129, 129);
}
::v-deep .filter-header header {
	font-weight: 500;
	color: black;
	width: 240px;
}
</style>
